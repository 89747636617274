<template>
  <div class="form-label-group select-box" :class="className">
    <model-select
      :id="id"
      :options="options"
      v-model="theValue"
      :placeholder="title ? title : $t('notFound')"
    >
    </model-select>
    <label :for="id">{{ title ? title : $t("notFound") }}</label>
    <img :src="require('@/assets/images/' + imgName)" />
  </div>
</template>

<script>
import { ModelSelect } from "vue-search-select";

export default {
  name: "CustomSelectBox",
  components: {
    ModelSelect,
  },
  data() {
    return {
      theValue: {
        value: this.value,
        text: "",
      },
    };
  },
  methods: {},
  props: {
    className: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "textarea",
    },
    value: {
      default: "",
    },
    title: {
      type: String,
      default: "title",
    },
    imgName: {
      type: String,
      default: "man.svg",
    },
    options: {
      type: Array,
      // default: [],
    },
  },
  watch: {
    theValue: function (val) {
      this.$emit("changeValue", val.value);
    },
    value: function (val) {
      if (val == "") {
        this.theValue = {
          value: "",
          text: "",
        };
      }
    },
  },
  async created() {},
};
</script>

<style lang="scss" scoped></style>
